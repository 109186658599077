import React, {useEffect, useState, useCallback} from "react";
import {getItem, getStorage, removeItem, setItem} from "../utils/storage";
import {useLocation, useNavigate} from "react-router-dom";
import {useDate} from "../useDate";
import {useForm} from "react-hook-form";
import {axiosInstance as axios} from "../utils/axios";
import {decodeUrlHashString, getUrlParameterByName} from "../utils/helpers";
import {
    ActionSheet, ActionSheetButton,
    BackButton,
    Button,
    Card,
    Checkbox,
    Page,
    ProgressCircular,
    Radio,
    Select,
    Toolbar
} from "react-onsenui";
import {ErrorMessage} from "@hookform/error-message";
import {makeDate_Std, makeTime} from "../utils/date-helper";
import Lottie from "lottie-react";
import animInfoBlue from "../assets/info-blue.json";
import {paths} from "../App";
/* Webcam for taking photos */
import Webcam from "react-webcam";
import { Icon } from '@iconify/react';
import { Controller } from "react-hook-form";


export const ChecklistSheet = (props) => {
    const [authUser, setAuthUser] = useState(getStorage('u4'));
    
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    /* Navigation */
    const navigate = useNavigate();
    const location = useLocation();
    const goBack = () => {
        // navigate(-1); // This wont work if user is coming directly to CL details page from QR code; No history;
        navigate(paths.checklist.dash, {replace:true});
    }

    /* Unsaved changes? @todo: fix this code, it's not working */
    useEffect(() => {
        const handleBackNavigation = () => {
            if (hasUnsavedChanges && !window.confirm("You have unsaved changes. Are you sure you want to leave this page?")) {
                navigate(-1, { replace: true });
            }
        };
        window.addEventListener("popstate", handleBackNavigation);
        return () => {
            window.removeEventListener("popstate", handleBackNavigation);
        };
    }, [hasUnsavedChanges, location, navigate]);

    /* Passed data */
    const passedData = location?.state?.data || {};
    
    /* State */
    const [imageArray, setImageArray] = useState([]);
    const [enableWebCam, setEnableWebCam] = useState(false);
    const [currentWebCamIndex, setCurrentWebCamIndex] = useState(false);
    const [showOptions, setShowOptions] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const dateRT = useDate();

    const [loc, setLoc] = useState(null); // location
    const [bpId, setBpId] = useState(null); //clBPId
    const [clBP, setClBP] = useState(null); // BP
    const [locId, setLocId] = useState(null); // locID
    const [orgId, setOrgId] = useState(null);
    const [clInstance, setClInstance] = useState(null); // clInstance
    const [decodedData, setDecodedData] = useState(null);
    const [clInstanceNotFound, setClInstanceNotFound] = useState(false);
    const [lastWorkedInstance, setLastWorkedInstance] = useState(false);
    const [clComments, setClComments] = useState([]);
    const [openSectionIndex, setOpenSectionIndex] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [validationError, setValidationError] = useState('');

    /* LocalStorage data into a variable */
    const [localStorageInstanceData, setLocalStorageInstanceData] = useState(null);

    /* Form */
    const { register, handleSubmit, watch, reset, setValue, getValues, formState: { errors } } = useForm();

    const getWhatCLToWorkOn = async () => {
        const roleId = parseInt(getStorage('u4R')) || null;
        return axios.post("/checklist/fetch-workable-instance", {
            clBP_id: bpId,
            loc_id: locId,
            role_id: roleId,
            org_id: orgId,
        });
    }

    const getApiCLBlueprintData = async (clBP_id=bpId) => {
        if(!clBP_id) return;
        return axios.get("/checklist/blueprint/get", { params: { type: "blueprint", id: clBP_id, } });
    }

    /* Get CL Comments * /
    const getApiComments = async () => {
        if(!authUser) return;
        const results = axios.get("/checklist/comments/list", { params: {
                org_id: authUser?.hotel_id,
                type: "comment",
        }})
        return results;
    }
    useEffect(()=>{
        getApiComments().then(r=>setClComments(r?.data?.data)) // paginated // disabled -- not used, so
    },[]);
    /* */

    const localDecode = passedData?.localDecode??false;
    const localInstanceId = passedData?.clInstance_id??0;

    /* Reset the form AFTER we get clInstance and clBp set */
    useEffect(()=> {
        // if(!clInstance || !clBP) return;
        if(localDecode && localInstanceId) {
            const lsObject = getItem(`cache_instance_${localInstanceId}`);
            reset(lsObject?.formData??{});
        }
    },[localDecode,localInstanceId,clBP]);


    /* VERY, VERY IMPORTANT: */
    /* Optimization is pending... Images are in an [] with key as index ... So item 100 will have images [,,,,,100] */
    /* Need to make this {id:x, img:''} format and use find(), filter() etc. */
    /* Applies to items_with_meta, imagesArray */
    /* ====== UPDATE 20 Apr 2023 - RECODED WITH ImageArray.FIND() AND ITEMS_WITH_META_xyz => FIXED? */

    /* Initialize */
    useEffect(() => {

        /* Process from Local ? */
        if(localDecode && localInstanceId) {
            setLoadingMessage("Retrieving saved Form");
            const lsObject = getItem(`cache_instance_${localInstanceId}`);
            if(lsObject) {
                let clBp_id_local = lsObject?.clInstance?.clBP_id ?? 0;
                setBpId(clBp_id_local);
                setLocalStorageInstanceData(lsObject);
                setClInstance(lsObject?.clInstance??{});
                setImageArray(lsObject?.imageArray??[]);
                setLocId(lsObject?.clInstance?.clLoc_id);
                setOrgId(lsObject?.clInstance?.org_id);
                setTimeout(()=>{ setLoadingMessage('Decoding images') },1100);
                setTimeout(()=>{ setLoadingMessage('') },2200);
                /* Blueprint not saved locally? Refetch */
                if(lsObject?.clBp && lsObject?.clBp?.id) {
                    setClBP(lsObject?.clBp??{});
                } else {
                    console.log("Refetehcing clBP")
                    getApiCLBlueprintData(clBp_id_local).then( r => {
                        setClBP(r.data); // also triggers reset of formdata
                    });
                    return;
                }
            } else {
                setTimeout(()=>{ setLoadingMessage('Error loading local data. Going back.') },50);
                setTimeout(()=>{ navigate(paths.checklist.dash) },500);
            }
            return;
        }

        /* Decode URL PARAMS */
        const hash = getUrlParameterByName('hash',passedData.decodedText);
        const decoded = decodeUrlHashString(hash);
        console.log('passedData',passedData);
        console.log('params', hash);
        console.log('decoded', decoded);
        console.log('decoded bpi',decoded.bpi,'bpn',decoded.bpn);
        console.log('decoded loi',decoded.loi,'lon',decoded.lon);
        if(!decoded.bpi||!decoded.loi||!hash) {
            alert("Invalid QR Code");
            navigate(paths.checklist.dash)
        }
        /* Set Decoded */
        setDecodedData(decoded);
        /* Set BpId */
        setBpId(decoded?.bpi || props?.bpi);
        /* Set LocId */
        setLocId(decoded?.loi || props?.loi);
        /* Set Org_id -- important to take blueprint's org */
        setOrgId(decoded?.oi || props?.oi || authUser?.hotel_id);
    },[]);

    /* Fetch Location Details */
    useEffect(() => {
        if(!locId || !clBP) return;
        const locFromBP = clBP?.locations?.find(l=>l.id==locId);
        if(!locFromBP) {
            // Fetch using Axios
        } else {
            setLoc(locFromBP);
        }
    },[locId,clBP]);

    /* Find an Instance to work on */
    useEffect(() => {

        if(!bpId || !decodedData) return; // Not ready

        if(localDecode) return; // Working on locally saved copy

        /* ORG CHECK: Check if user has permission to work on CL [#60] */
        if(!authUser?.hotel_id) return;
        const usersOrgIds = authUser.hotels?.map(h=>h.id) ?? [authUser?.hotel_id ?? 0]; 
        if(usersOrgIds.indexOf(decodedData?.oi)===-1) {
            alert("You do not have permission to work on this checklist.");
            navigate(paths.checklist.dash);
            return;
        }

        /* Get API Data */
        getWhatCLToWorkOn().then( r => {
            if( (r.data.hasOwnProperty('workable-instance') && !r.data["workable-instance"]) || r.data==='INSTANCE-NOT-FOUND') {
                setLastWorkedInstance(r.data['last-worked-instance'] ?? 'not-found');
                setClInstanceNotFound(true);
            }
            else if(r.data && r.data?.id) {
                setClInstance(r.data);
                setClInstanceNotFound(false);
                getApiCLBlueprintData().then( r => {setClBP(r.data);}); // Get blueprint master details (on success of instance)
            }
            setLoadingMessage(''); // reset loading
            /* Save to localStorage cache: Instance details */
            const instanceId = clInstance?.id ?? r.data?.id ?? 0;
            setItem(`cache_instance_${instanceId}.clInstance`,r.data??{});
        }); // Get instance details
    },[bpId]);

    const capturedImageData = (data) => {
        if("cancelled"!==data) {
            // console.log('currentWebCamIndex',currentWebCamIndex)
            let images = [...(imageArray.filter(obj=>obj.img!==null))]; // remove nulls (from old code)
            let imgNew = { id:currentWebCamIndex, img:data };
            // Image with id already exists?
            const imgIdIndex = imageArray.findIndex(imgObj=>imgObj.id===currentWebCamIndex);
            if(imgIdIndex===-1) {
                images.push(imgNew)
            } else {
                images[imgIdIndex] = imgNew;
            }
            setValue(`items_with_meta_${currentWebCamIndex}[meta][imgUp]`,true);
            setImageArray(images);
            saveChecklistLocally(false);
        }
        setEnableWebCam(false);
    }
    const retake = (i) => {
        setEnableWebCam(true);
        setCurrentWebCamIndex(i);
    }
    const deletePhoto = (i=currentWebCamIndex) => {
        const images = imageArray;
        const delIndex= images.findIndex(e=>e.id===i);
        images[delIndex].img = null;
        images[delIndex].id=null
        setValue(`items_with_meta_${i}[meta][imgUp]`,null);
        setImageArray(images);
        setEnableWebCam(false);
        saveChecklistLocally(false);
    }

    /* Save the ClInstance data locally to LS */
    useEffect(() => {
        saveChecklistLocally(false,true);
    },[imageArray, clBP, clInstance]);
    const saveChecklistLocally = (exitPage=false, silent=false) => {
        const instanceId = clInstance?.id??0;
        let formData = getValues();
        /* If user clicks save (or back button) before API response of Instance or BP, do nothing */
        if(!instanceId||!clInstance||!clBP||!formData) {
            return;
        }
        /* Save to localStorage */
        //Since we are setting item[id] as index, there will be nulls in empty indexes,
        //we need to remove those and compact the array
        formData.items_with_meta = formData.items_with_meta?.filter(e=>e!==null);
        setItem(`cache_instance_${instanceId}.formData`,formData);
        setItem(`cache_instance_${instanceId}.imageArray`,imageArray);
        setItem(`cache_instance_${instanceId}.clBp`,clBP);
        setItem(`cache_instance_${instanceId}.clInstance`,clInstance);
        setItem(`cache_instance_${instanceId}.timestamp`,new Date().toISOString());
        if(silent) return;
        /* Async queue */
        setLoadingMessage("Saving");
        setTimeout(()=> { setLoadingMessage('Saving images') },500);
        setTimeout(()=> {
            setLoadingMessage('');
            if(exitPage) goBack();
        },1000);
    }

    const deleteSavedChecklist = (exitPage=false) => {
        /* localStorage cache: Delete from cache */
        removeItem(`cache_instance_${clInstance?.id??0}`);
        removeItem(`cache_instance_`);removeItem(`cache_instance_undefined`);removeItem(`cache_instance_0`);
        if(exitPage) {
            setLoadingMessage("Deleting local data");
            setTimeout(()=>{ setLoadingMessage('Deleting images') },600);
            setTimeout(()=>{
                setLoadingMessage('');
                goBack();
            },1200);
        }
    }

    const submitChecklist = (formData) => {
        setValidationError('');
        if(!formData) return;

        // Extract all items_with_meta keys
        const itemsWithMetaKeys = Object.keys(formData).filter(key => key.startsWith("items_with_meta_"));

        if (itemsWithMetaKeys.length === 0) {
            setValidationError('Please complete all required sections before submitting.');
            setLoadingMessage('');
            return;
        }
        
         // Go through each section to ensure it is opened and its fields are filled
        const invalidSections = clBP?.sections?.filter((section) => {
            const sectionItems = clBP?.items?.filter((item) => item?.pivot?.section == section.id);
            return sectionItems.some((item) => {
                const itemKey = `items_with_meta_${item.id}`;
                const formItem = formData[itemKey];

                // Check if the item is filled out properly (especially closing_option)
                const isInvalid = !formItem?.meta?.closing_option || formItem?.meta?.closing_option.trim() === "";
                
                if (isInvalid) {
                    console.log(`Item ${item.id} is invalid. Missing or empty 'closing_option'.`);
                }
                return isInvalid;
            });
        });
        if (invalidSections.length > 0) {
            setValidationError('Please fill out all required fields before submitting.');
            setLoadingMessage('');
            return;
        }

        /* set it for submission */
        formData.checklist_type = "instance";
        formData.clInstance_id = clInstance?.id??0;

        //Since we are setting item[id] as index, there will be nulls in empty indexes,
        //we need to remove those and compact the array
        formData.items_with_meta = formData.items_with_meta?.filter(e=>e!==null);

        let imageArrayFormData = imageArray;
        imageArrayFormData = imageArrayFormData?.map((img,i)=>{return {id:img.id,img:img.img}}).filter(e=>e?.img!==null);
        imageArrayFormData = {
            clInstance_id: formData.clInstance_id,
            images: imageArrayFormData,
            ts_precise: new Date(),
            type: 'base64',
        }

        console.log('formData',formData);
        console.log('imageArrayFormData',imageArrayFormData);

        setLoadingMessage('Submitting Checklist');

        /* Convert items_with_meta_xyz to items_with_meta[] for API POST call */
        const items_with_meta = [];
        let formDataPostProc = {...formData};
        for (const key in formDataPostProc) {
            if (key.startsWith("items_with_meta_")) {
                items_with_meta.push(formDataPostProc[key]);
                delete formDataPostProc[key];
            }
        }
        formDataPostProc.items_with_meta = items_with_meta;
        /* /end postProcessing of formData copy */

        const respSyncItems = axios.post("/checklist/sync-items", formDataPostProc ).then( r => {
            console.log('cl/sync-items response',r.data);
            setLoadingMessage('Uploading Images');
            const respSyncImages = axios.post("/checklist/sync-images",imageArrayFormData,{
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    console.log('progressEvent',percentCompleted);
                    setLoadingMessage(`Uploading Images: ${percentCompleted}%`);
                }
            })
            .then( r => {
                setHasUnsavedChanges(false);
                setTimeout(()=>{
                    setLoadingMessage('');
                    deleteSavedChecklist(false);
                    navigate(paths.checklist.done);
                },500);
            }).catch(e=>{setLoadingMessage('')});
        }).catch(e=>{setLoadingMessage('')});
    }

    const renderMandatory = (bit,textOnly=false) => bit?(textOnly?' *':<span style={{fontWeight:800,color:"red"}}>*</span>):'';

    const delimiter = ";";
    const basicValidations = {
        optionals: ['o','optional'],
        mandatory: ['m','mandatory'],
        mandatoryForTrigger: ['mft','mandatory-for-trigger']
    }

    const toggleSection = (index) => {
        setOpenSectionIndex((prevOpenSectionIndex)=>{
            if(prevOpenSectionIndex.includes(index)){
                return prevOpenSectionIndex.filter((i)=> i !== index);
            }else{
                return[...prevOpenSectionIndex,index];
            }
        });
    };
    const handleOptionChange = (formNameClosingOpt, value) => {
        setSelectedOptions((prev) => ({ ...prev, [formNameClosingOpt]: value }));
        setValue(formNameClosingOpt, value);
    };

    const RenderItem = (clItem, sectionId) => {
        const index = clItem.id;
        const random = Math.random();
        const imgAtt = clItem?.pivot?.meta?.img;
        const imgMandatory = !(basicValidations.optionals).includes(clItem?.pivot?.meta?.img_vl); //!optional
        const comAtt = clItem?.pivot?.meta?.com;
        const comMandatory = !(basicValidations.optionals).includes(clItem?.pivot?.meta?.com_vl); //!optional
        const formName = `items_with_meta_${index}`;
        const formNameSectionId = `${formName}[section]`;
        const formNameComment = `${formName}[meta][com]`;
        const formNameImgUser = `${formName}[meta][imgUp]`;
        const formNameClosingOpt = `${formName}[meta][closing_option]`;
        /* Check `Mandatory for Trigger Condition` * /
        if( getValues(formNameClosingOpt)===clItem?.trigger_on_option ) {
            console.log('matched case');
        }
        /* */
        const selectedClosingOpt = selectedOptions[formNameClosingOpt] || getValues(formNameClosingOpt) || '';
        const renderClosingOptionSelected = (formNameClosingOpt) => {
            const selectedClosingOpt = getValues(formNameClosingOpt);
            return (
                <span className={`p-t-b-5 p-l-r-10 d-b m-b-10 b-r-2 ${selectedClosingOpt?'bg-green-light':'bg-grey-light'}`}>
                Your selection: {
                    selectedClosingOpt?
                        <b>{selectedClosingOpt}</b> :
                        <span className={'f-s-09r'}>None</span>
                }
                </span>
            );
        }


        return (
            <div key={index} style={{order:clItem.pivot?.order??1}}>
                <Card>
                    <input name={formName+'[id]'} value={clItem?.id} {...register(formName+'[id]', {required: true})} className="form-input-hidden" />
                    <input name={formNameSectionId} value={sectionId} {...register(formNameSectionId, {required: true})} className="form-input-hidden" />

                    <div className={(imgAtt||imgMandatory)?"grid-checklist":"grid-checklist-no-img"}>
                        <div>
                            <h5>{clItem.name}</h5>
                            <textarea className="textarea textarea--transparent" rows="3" style={{width:'100%'}}
                                      name={formNameComment}
                                      placeholder={"Enter Comments"+renderMandatory(comMandatory,true)}
                                      {...register(formNameComment, { required: comMandatory, maxLength: 512 })}
                                      onChange={(e)=>setValue(formNameComment, e.target.value)}
                            />
                            <ErrorMessage errors={errors} name={formNameComment} render={({ message }) => <span className="inline-error inline-error-block">{message||"Comment Required"}</span>}/>

                            {/* ===== USER SELECTION ===== */}
                            {/* Show user's selection for LocalDecode mode --- inputs were not working */}
                            { localDecode ? renderClosingOptionSelected(formNameClosingOpt) : <></> }
                            {/* @todo below - make defaultChecked/defaultSelected values for each input type */}

                            {/* Render Input conditionally */}
                            <div>
                                {(() => {
                                    switch(clItem?.closing_type) {
                                        default:
                                        case 'radio':
                                        case 'radio-button':
                                            return (
                                                clItem?.closing_options?.split(delimiter).map((option,i) => {
                                                    return(
                                                        <label className="p-t-b-5 m-r-20 flex-vert-baseline flex-inline" key={i}>
                                                           <Radio
                                                            name={formNameClosingOpt}
                                                            value={option}
                                                            checked={selectedClosingOpt === option}
                                                            modifier='material'
                                                            {...register(formNameClosingOpt, { required: "Closing Option Required" })}
                                                            onChange={(e) => handleOptionChange(formNameClosingOpt, e.target.value)}
                                                        />
                                                            <span className="p-l-5">{option}</span>
                                                        </label>
                                                    )
                                                })
                                            );
                                        case 'dropdown':
                                            return (
                                                <Select style={{width:'100%',padding:'6px 10px'}}
                                                        modifier={'material'}
                                                        name={formNameClosingOpt}
                                                        {...register(formNameClosingOpt, {required: "Closing Option Required"})}
                                                        onChange={(e) => {
                                                            setValue(formNameClosingOpt, e.target.value);
                                                            console.log(formNameClosingOpt, e.target.value);
                                                        }}
                                                >
                                                    <option value={''} className="d-i-b p-t-b-5 p-l-r-10">Select</option>
                                                    {clItem?.closing_options?.split(delimiter).map((option, i) => {
                                                        return (
                                                            <option key={i} value={option} className="d-i-b p-t-b-5 p-l-r-10">{option}</option>
                                                        )
                                                    })}
                                                </Select>
                                            );
                                        case 'checkbox':
                                            return (<>
                                                    <fieldset style={{appearance:"none",border:0,padding:0,margin:0}}>
                                                        {clItem?.closing_options?.split(delimiter).map((option, i) => {
                                                            return (
                                                                <label key={i} className="p-t-b-5 m-r-20 flex-vert-baseline flex-inline">
                                                                    <Checkbox className="p-l-r-5"
                                                                              modifier={'material'}
                                                                              value={option}
                                                                              name={formNameClosingOpt}
                                                                              {...register(formNameClosingOpt, {required: "Closing Option Required"})}
                                                                              onChange={(e) => {
                                                                                  let existing = getValues(formNameClosingOpt)?.split(delimiter)||[];
                                                                                  const checked = !!(e.target.checked)??false;
                                                                                  if(checked) {
                                                                                      if(!existing.includes(e.target.value)) { existing.push(e.target.value) }
                                                                                  } else {
                                                                                      existing = existing.filter( (arrEl) => arrEl!==e.target.value )
                                                                                  }
                                                                                  let newValue = existing.join(delimiter);
                                                                                  if(delimiter===';') newValue = newValue.replace(/^;+|;+$/g, '');
                                                                                  if(delimiter===',') newValue = newValue.replace(/^,+|,+$/g, '');
                                                                                  setValue(formNameClosingOpt, newValue);
                                                                                  console.log(formNameClosingOpt, newValue);
                                                                              }}
                                                                    /> { option }
                                                                </label>
                                                            )
                                                        })}
                                                    </fieldset>
                                                </>
                                            );
                                            case 'textfield':
                                            return (
                                                <input
                                                    name={formNameClosingOpt}
                                                    {...register(formNameClosingOpt, { required: "Closing Option Required" })}
                                                    placeholder="Enter Details here..."
                                                    onChange={(e) => {
                                                        setValue(formNameClosingOpt, e.target.value);
                                                        console.log(formNameClosingOpt, e.target.value);
                                                    }}
                                                    style={{
                                                        width: '100%',
                                                        padding: '12px',
                                                        fontSize: '16px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        boxSizing: 'border-box',
                                                        transition: 'border-color 0.2s ease-in-out',
                                                        outline: 'none',
                                                        }}
                                                        // Example of focus style
                                                        onFocus={(e) => {
                                                        e.target.style.borderColor = '#2196F3';
                                                        }}
                                                        // Example of blur style
                                                        onBlur={(e) => {
                                                        e.target.style.borderColor = '#ccc';
                                                    }}
                                                />
                                            );
                                    }
                                })()}
                                <ErrorMessage errors={errors} name={formNameClosingOpt} render={({ message }) => <span className="inline-error inline-error-block">{message}</span>}/>
                            </div>
                        </div>


                        {/* Photo/Image */}
                        { (imgAtt || imgMandatory) &&
                            <div className="grid-checklist-content">
                                { imageArray.find(x=>x.id===index) && <img src={imageArray.find(x=>x.id===index).img} style={{width: 'auto', height: '110px', objectFit: "cover", borderRadius: 5}} /> }
                                { enableWebCam && (index===currentWebCamIndex) && <WebcamCapture capturedImageData={capturedImageData}/> }
                                { imageArray.find(x=>x.id===index) &&
                                    <div className="grid grid-2x text-a-center">
                                        <Button modifier="quiet" onClick={()=>retake(index)}>
                                            <span className="fa fa-refresh"></span> Retake
                                        </Button>
                                        <Button modifier="quiet" onClick={()=>deletePhoto(index)}>
                                            <span style={{color: 'red'}}><span className="fa fa-trash"></span> Remove</span>
                                        </Button>
                                    </div>
                                }
                                { imgAtt && !imageArray.find(x=>x.id===index) &&
                                    <div className="text-a-right w-100-pc">
                                        {/* Take Photo */}
                                        <Button modifier="quiet" onClick={()=>retake(index)}><span className="fa fa-camera"></span> Take photo {renderMandatory(imgMandatory)}</Button>
                                        {/* Browse * /}
                                        <Button modifier="quiet" onClick={() => {}}><span className="fa fa-folder-open"></span> Browse</Button>
                                        {/* Validation for Image */}
                                        <span>
                                            <textarea className="textarea textarea--transparent" rows="1" style={{display:'none'}}
                                                      name={formNameImgUser}
                                                      placeholder={"Image"+renderMandatory(comMandatory,true)}
                                                      {...register(formNameImgUser, { required: imgMandatory, maxLength: 5 })}
                                                      onChange={(e)=>setValue(formNameImgUser, e.target.value)}
                                            />
                                            <ErrorMessage errors={errors} name={formNameImgUser} render={({ message }) =>
                                        <span className="inline-error inline-error-block" style={{paddingRight:21,marginTop:-12}}>{message||"Photo Required"}</span>
                                    }/>
                                    </span>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {
                        (clItem?.meta && (clItem?.meta['trigger_job']??false)) // isEnabled?
                        && clItem?.item_id // Valid ReqItem?
                        && String(getValues(formNameClosingOpt))?.split(delimiter).includes(clItem?.trigger_on_option) // Selected Trigger is same as Trigger Option?
                        && <>
                            <hr className="thin"/>
                            <div className="checklist-item-footer">
                                <span>
                                    <span className="notification notification--material"><small>Info</small></span>{' '}
                                    <b>{clItem?.trigger_on_option}</b>{' '}
                                    triggers job <b>{clItem?.trigger_item?.name}</b>{' '}
                                    for department <b>{clItem?.trigger_item?.desk?.name}</b>
                                </span>
                            </div>
                            {/* Check if a similar job already exist and display radio buttons to decide what to do */}
                            <SimilarJobExists item_id={clItem?.item_id} loc={decodedData?.lon} register={register} errors={errors} />                            
                        </>
                    }
                </Card>
                {/* Spacer */}
                <div className="d-b" style={{padding:0, paddingBottom:'1px', height:0, margin:0}} />
            </div>
        );
    }

    const enableRenderId = true;
    const renderId = (str) => (enableRenderId&&str)?<span className="opa-050 p-l-r-5">(#{str})</span>:null;

    return (
        <>
            {/* Fullscreen Loading Messages */}
            { (loadingMessage) &&
                <div className="flex flex-both-center flex-dir-col h-100-vh flex-dir-col animate__animated" style={{position:"absolute", width:"100%", background: "rgba(255,255,255,0.95)", zIndex: 100}}>
                    <ProgressCircular modifier="material" indeterminate={true} />
                    <h3>{loadingMessage||"Fetching..."}</h3>
                </div>
            }
            <Page renderToolbar={() =>
                <Toolbar>
                    <div className="left">
                        <BackButton onClick={()=> {saveChecklistLocally(true)}}>Back</BackButton>
                    </div>
                    <div className="center">
                        <small>{dateRT}</small>
                    </div>
                    <div className="right">
                        <span className="p-l-r-10 f-s-1r"><span className="fa fa-user-shield"></span>{' '}<b>{authUser?.fname||''}</b></span>
                        {/* ACTIONS */}
                        {/*<Button modifier="quiet" onClick={()=>setShowOptions(true)}><span className="fa fa-cogs"></span></Button>*/}
                        {/*<label className="flex flex-both-center p-r-10" style={{justifyContent:'right'}}>
                    <Checkbox onChange={(event)=>{}} modifier='' /> {' '} Compact
                </label>*/}
                    </div>
                </Toolbar> }>
                { clBP &&
                    <form onSubmit={handleSubmit(submitChecklist)}>

                        <div className="p-l-r-15">

                            <div className="p-t-5 p-l-r-0">
                                <h3 className="p-0 m-0 text-a-left"><b>{clInstance?.name}</b>&nbsp;<small className="f-w-400">(ID: {clInstance?.id})</small></h3>
                                <div className="grid grid-3x grid-4x-responsive grid-gap-10 text-a-left flex-horiz-space-bw p-5">
                                    { clBP?.desk_id && <p className="p-0 m-0"><span className="fa fa-building-o p-r-2"></span>{' Department: '}{clBP?.desk?.name}</p> }
                                    { clBP?.id && <p className="p-0 m-0"><span className="fa fa-edit p-r-2"></span>{' Template: '}{clBP?.name}</p> }
                                    <p className="p-0 m-0"><span className="fa fa-clock-o p-r-2"></span>{' Validity: '}{makeTime(clInstance?.ts_start)} - {makeTime(clInstance?.ts_end)}</p>
                                    { loc && <p className="p-0 m-0"><span className="fa fa-map-marker-alt p-r-2"></span>{' Area/Location: '}{loc?.location}</p> }
                                </div>
                                <hr className="thin" />
                            </div>

                            <input name="clBp_id" value={bpId} {...register("clBp_id", {required: true})} className="form-input-hidden" />


                           {clBP?.sections?.map((section, sectionIndex) => (
                <div key={sectionIndex} style={{ marginBottom: '20px' }}>
                    <div
                        style={{
                            cursor: 'pointer',
                            padding: '10px',
                            background: openSectionIndex.includes(sectionIndex) ? '#f0f0f0' : '#ffffff',
                            borderBottom: '1px solid #ddd',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                        onClick={() => toggleSection(sectionIndex)}
                    >
                        <h3 style={{ margin: '10px 10px 10px' }}>{sectionIndex + 1}. {section.name}</h3>
                        <Icon icon={openSectionIndex.includes(sectionIndex) ? 'mdi:chevron-up' : 'mdi:chevron-down'} />
                    </div>
                    {openSectionIndex.includes(sectionIndex) && (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {clBP?.items?.filter((item) => item?.pivot?.section == section.id).map((clItem, clItemIndex) => (
                                RenderItem(clItem, section.id)
                            ))}
                        </div>
                    )}
                </div>
            ))}

                            <div className="cl-footer m-t-30 m-b-20">

                                <h5>Save locally</h5>
                                <div style={{display:'grid', gridGap:20, gridTemplateColumns:'repeat(2,1fr)'}}>
                                    {/* Save Button */}
                                    <div className="m-b-20 d-b">
                                        <Button modifier="large--cta" onClick={()=>saveChecklistLocally(false)} style={{backgroundColor: "#4d8722", borderColor: "#4d8722"}}>
                                            Save
                                        </Button>
                                    </div>
                                    {/* Delete Button */}
                                    <div className="m-b-20 d-b">
                                        <Button modifier="large--cta" disabled={!localDecode} onClick={()=>deleteSavedChecklist(true)} style={{backgroundColor: "#753020", borderColor: "#753020"}}>
                                            Delete
                                        </Button>
                                    </div>
                                </div>

                                {/* Error exists ? */}
                                { (errors?.items_with_meta?.length ?? null ) &&
                                    <span className="col-red p-b-5 d-b text-a-center f-s-09r">
                    Please fill all required fields
                    </span>
                                }
                                
                                {validationError && (
                                    <span className="col-red p-b-5 d-b text-a-center f-s-09r">
                                        {validationError}
                                    </span>
                                )}
                                <Button modifier="large--cta" onClick={handleSubmit(submitChecklist)}>
                                    Submit Checklist
                                </Button>
                                {/* Logged in user info */}
                                {authUser && <div style={{color:'darkgray',textShadow:'0 1px 0 white',marginTop:20,marginBottom:0,textAlign:"center"}}>Submitting as {authUser?.name??authUser?.fname??'Unknown'}</div>}
                            </div>

                        </div>
                        {/* Options */}
                        <ActionSheet isOpen={showOptions} isCancelable={false}>
                            <ActionSheetButton onClick={()=>{}}>
                                Reset Form
                            </ActionSheetButton>
                            <ActionSheetButton onClick={()=>{}}>
                                Mark all as `Yes`
                            </ActionSheetButton>
                            <ActionSheetButton onClick={()=>setShowOptions(false)}>
                                Cancel
                            </ActionSheetButton>
                        </ActionSheet>
                    </form>
                }
                {/* Zero State */}
                { !clBP && <div className="flex flex-both-center flex-dir-col w-100-pc" style={{height:'100%',minHeight:'80vh'}}>
                    {/* Loading */}
                    { !clInstanceNotFound && <ProgressCircular indeterminate /> }
                    {/* NOT FOUND */}
                    { clInstanceNotFound &&
                        <div className="text-a-center" style={{maxWidth:'86vw'}}>
                            <Lottie animationData={animInfoBlue} loop={true} style={{width: 90, height: 90, margin: '0 auto'}}/>
                            <h3 className="f-s-1-8r f-w-800">Valid checklist not found</h3>
                            <div className="w-100-pc d-b text-a-left">
                                <small className="d-b p-t-b-10 f-w-600 opa-050">QR SCAN DETAILS:</small>
                                <small className="d-b p-t-b-5">Selected Role: <b>{authUser?.roles?.find(role => role.id === parseInt(getStorage('u4R')))?.name??"Not Set"}</b>{renderId(getStorage('u4R'))}</small>
                                <small className="d-b p-t-b-5">Checklist: <b>{decodedData?.bpn}</b>{renderId(decodedData?.bpi)}</small>
                                <small className="d-b p-t-b-5">Location: <b>{decodedData?.lon}</b>{renderId(decodedData?.loi)}</small>
                            </div>
                            {
                                (!!lastWorkedInstance) &&
                                <small className="w-100-pc text-a-left d-b l-h-1r card-box-simple">
                                    Last submitted similar checklist:
                                    { lastWorkedInstance === 'not-found' ?
                                        <span className="d-b">Not found</span> :
                                        <ul className="p-l-20">
                                            <li>{ lastWorkedInstance.name } (#{lastWorkedInstance.id})</li>
                                            <li>Generated at: {makeDate_Std(lastWorkedInstance.created_at)}</li>
                                            <li>Validity: {makeTime(lastWorkedInstance.ts_start??'')} - {makeTime(lastWorkedInstance.ts_end??'')}</li>
                                            <li>Actioned by: { lastWorkedInstance.actioned_by?.name ?? '-' }</li>
                                            {lastWorkedInstance?.actioned_by && <li>Actioned at: {makeDate_Std(lastWorkedInstance.user_actioned_at ?? lastWorkedInstance.updated_at)}</li>}
                                        </ul>
                                    }
                                </small>
                            }
                        </div>
                    }
                </div>
                }
            </Page>
        </>
    );
}

// React component to check for similar jobs based on item_id and loc,
// and prompt the user to trigger a duplicate job or not.
const SimilarJobExists = ({ item_id, loc, register, errors }) => {
    const [similarJobs, setSimilarJobs] = useState([]);
    const [showSimilarJobs, setShowSimilarJobs] = useState(false);
    useEffect(() => {
        fetchSimilarJobs();
    }, [item_id, loc]);

    // Function to make the API call to check for similar jobs and update the state
    const fetchSimilarJobs = async () => {
        try {
            const response = await axios.get('/check-similar-jobs', {
                params: { item_id, loc },
            });            
            console.log('Similar jobs API response:', response.data);
            setSimilarJobs(response.data.similarJobs);
        } catch (error) {
            console.error('Error checking similar jobs:', error);
        }
    };
    //Copy to clipboard function.
    function copyToClipboard(text) {
        navigator.clipboard.writeText(text)
            .then(() => {
                console.log('Text copied to clipboard');
            })
            .catch(err => {
                console.error('Error copying text to clipboard: ', err);
            });
    }
    

    // Render content based on the length of similar jobs
    return similarJobs.length > 0 ? (
        <>
            <hr className="thin" />
            <div className="checklist-item-footer" style={{}}>
                <span>
                    <small>⚠️</small>{' '}<b>{similarJobs.length === 1 ? '1 similar work order' : similarJobs.length > 1 ? `${similarJobs.length} similar work orders exist` : null} in pending or parked state. Trigger Duplicate Work Order ?</b>
                </span>
                <div style={{ display: "grid", fontSize: "15px", textAlign: "center", alignItems: "center", justifyContent: "center" }}>
                    {/* Radio button for Trigger Duplicate */}
                    <label style={{ display: "flex" }}>
                        <input
                            type="radio"
                            name="duplicateOptions"
                            value="triggerDuplicate"
                            required
                            {...register("duplicateOptions", { required: "Trigger Duplicate Option Required" })}
                        />
                        Yes
                    </label>

                    {/* Radio button for Proceed Without Duplicate */}
                    <label style={{ display: "flex" }}>
                        <input
                            type="radio"
                            name="duplicateOptions"
                            value="proceedWithoutDuplicate"
                            required
                            {...register("duplicateOptions", { required: "Trigger Duplicate Option Required" })}
                        />
                        No
                    </label>
                    <ErrorMessage errors={errors} name="duplicateOptions" render={({ message }) => <span className="inline-error inline-error-block">{message}</span>} />
                </div>
            </div>
            <Button style={{margin:'20px', display: 'flex', justifyContent: 'center', color:"white", backgroundColor:"#1f406b", borderRadius:"15px"}} onClick={() => setShowSimilarJobs(!showSimilarJobs)}>View Duplicate Jobs</Button>
            {showSimilarJobs && similarJobs.length > 0 && (
                <>
                    <div style={{paddingBottom:"5px"}}><small>View Desktop App for more info.</small></div>
                    <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                        <table style={{ marginTop: '10px', borderCollapse: 'collapse', width: '100%', fontSize: '14px' }}>
                            {/* Render headers */}
                            <thead style={{backgroundColor:"#84a0c4",color:"white"}}>
                                <tr>
                                    <th style={{ padding: '8px' }}>Job ID</th>
                                    <th style={{ padding: '8px' }}>Comment</th>
                                    <th style={{ padding: '8px' }}>Created On</th>
                                    <th style={{ padding: '8px' }}>Created By</th> 
                                </tr>
                            </thead>
                            {/* Render rows */}
                            <tbody>
                                {similarJobs.map((job) => (
                                    <tr key={job.id}>
                                        <td style={{ padding: '10px', display:'flex' }}>
                                            {job.id}
                                            <button
                                                type="button" 
                                                onClick={() => copyToClipboard(job.id)}
                                                style={{
                                                    fontSize: '12px',
                                                    marginLeft:"5px",
                                                    border: 'none',
                                                    cursor: 'pointer', 
                                                    color: 'black', 
                                                    backgroundColor:'transparent'
                                                    
                                                }}
                                            >
                                               <span className="fa fa-copy"/>
                                            </button>
                                        </td>
                                        <td style={{ padding: '10px' }}>{job.comments?? 'None'}</td>
                                        <td style={{ padding: '10px' }}>{job.created_at}</td>
                                        <td style={{ padding: '10px' }}>{job.fname}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </>                
                )}
        </>
    ) : null;
};


export const WebcamCapture = ({capturedImageData}) => {
    const webcamRef = React.useRef(null);

    const [imgSrc, setImgSrc] = React.useState(null);
    const [enableWebcam, setEnableWebCam] = React.useState(true);

    const capture = React.useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        if(imageSrc) setEnableWebCam(false);
    }, [webcamRef, setImgSrc]);

    const retake = () => {
        setImgSrc('');
        setEnableWebCam(true);
    }
    const cancelAction = () => {
        capturedImageData("cancelled");
    }
    const usePhoto = () => {
        capturedImageData(imgSrc);
    }

    const videoConstraints = {
        width: 800,
        height: 600,
        facingMode: "environment"
    };

    return (
        <div>
            {/* Image */}
            <div className="Page__fauxFullscreen" style={{position:'fixed', zIndex:99999, top:0, left:0, width:'100%', height:'100vh'}}>
                <Page>
                    <div className="flex flex-both-center flex-dir-col p-t-b-0 w-100-pc">
                        { enableWebcam &&
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                            />
                        }
                        { !enableWebcam && imgSrc && (
                            <img src={imgSrc} alt="Captured image" />
                        )}
                    </div>
                </Page>

                {/* Action buttons */}
                <div className="p-10 p-t-0" style={{position:'absolute', bottom:'17%', zIndex: 999999 }}>
                    { enableWebcam && <>
                        <button className="btn-black btn-over-image m-l-r-10" onClick={capture}>Capture photo</button>
                    </>
                    }
                    { !enableWebcam && <>
                        <button className="btn-black btn-over-image m-l-r-10" onClick={retake}>Retake photo</button>
                        <button className="btn-black btn-over-image m-l-r-10" onClick={cancelAction}>Cancel</button>
                        <button className="btn-black btn-over-image btn-blue m-l-r-10" onClick={usePhoto}>Use photo</button>
                    </>}
                </div>

            </div>

        </div>
    );
};